$(document).ready(function() {
	$('#insight-boards-select').on('change', function() {
		window.location = '/insight_boards/' + $(this).val();
	});

	$('.datepicker').datetimepicker({
		format: 'YYYY-MM-DD'
	});

	$('#selection-section').on('click', '.brands-skus',  function() {
		var val = $(this).val();
		if (val == 'brands') {
			$('#strategy_brand_ids').removeAttr('disabled');
			$('#strategy_brand_ids').attr('required', true);
			$('#strategy_sku_ids').attr('disabled', true);
			$('#strategy_sku_ids').removeAttr('required');
			$('#strategy_brand_ids').selectpicker('refresh');
			$('#strategy_sku_ids').selectpicker('refresh');

		} else if (val == 'skus') {
			$('#strategy_sku_ids').removeAttr('disabled');
			$('#strategy_sku_ids').attr('required', true);
			$('#strategy_brand_ids').attr('disabled', true);
			$('#strategy_brand_ids').removeAttr('required');
			$('#strategy_sku_ids').selectpicker('refresh');
			$('#strategy_brand_ids').selectpicker('refresh');
		}

	});

	$("#update_avatar .icon-pencil").click(function() {
		$("input[id='avatar']").click();
	});

	$("#update_avatar #avatar").change(function(){
		$("#update_avatar").submit();
	});

	setTimeout(function() {
		$('.alert').fadeOut('medium');
	}, 3000);

	$(".card-title.board-hover").hover(
		function() {
			var id = $(this).data("id");
			$(this).popover({
				html: true,
				trigger : 'hover',
				content: function() {
					return $("#board-" + id + "-retailer").html();
				}
			})
		}
	);
});

window.loadData = function(id, method_name) {

	var type = $(".brands-skus:checked").val();
	var category_id = $('#strategy_category_ids').val();
	var insight_board_id = $('#insight_board_id').val();

	if (id != "") {
		$.ajax({
			url: '/strategies/load_data',
			data: {
				id: id,
				method_name: method_name,
				type: type,
				category_id: category_id,
				insight_board_id: insight_board_id
			},
			method: 'get',
			dataType: 'script'
		});
	}
};


